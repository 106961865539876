import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import type { ExtractArrayType } from '@zento-lib/util/types';

import { fetchFreeGift, FetchFreeGiftQuery } from './operations.graphql';

export type Gift = ExtractArrayType<FetchFreeGiftQuery['freeGift']>['fields'];

@Store
export class FreeGiftStore extends BaseStore {
  @Field
  private giftData: Gift;

  public async fetchFreeGift() {
    let gift: FetchFreeGiftQuery = { freeGift: {} };

    try {
      gift = await this.dataSources.graphQl.queue({
        ...fetchFreeGift,
      });
    } catch (e) {
      console.error('Free Gift Store (fetchFreeGift): ', e);
    }

    if (gift && gift.freeGift?.fields) {
      this.giftData = gift.freeGift.fields;
    }

    return this.giftData;
  }

  public get freeGift() {
    return this.giftData || {};
  }
}
